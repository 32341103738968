import { get } from 'lodash';
import { getCategory } from '../selectors/categories-selectors';
import { getCommunityId } from '../selectors/forum-data-selectors';
import { getApplicationConfig } from '@wix/communities-forum-client-commons/dist/src/selectors/communities-context-selectors';
import { permissionsChecker } from '@wix/communities-forum-universal/dist/src/services/permissions/permissions-checker';
import { getCurrentUser } from '../store/current-user/current-user-selectors';
import {
  MEMBERS_APP_ID,
  SANTA_MEMBERS_APP_ID,
} from '@wix/communities-forum-universal/dist/src/constants/appsConfig';

/*
Which permissions checker should I use?

* can - use when you only care about if user can do action or not - using this carries a message
  that action is visible only when user can actually do it.
* canRender - use it everywhere where you render UI based on permissions.*/

const createPermissionsChecker = permissions => (permission, resource, obj) => {
  return permissions.can(permissions.resourcify(obj, resource), permission);
};

const createPermissionsRenderHelper = canChecker => (rules, render) => {
  const result = rules.map(([permission, resource, obj]) => canChecker(permission, resource, obj));
  const can = result.some(r => r === true);
  return can ? render(result) : null;
};

export const createHelpers = permissions => {
  const canPermissionsHelper = createPermissionsChecker(permissions);
  const permissionsRenderHelper = createPermissionsRenderHelper(canPermissionsHelper);

  return {
    permissions,
    can: canPermissionsHelper,
    canRender: permissionsRenderHelper,
  };
};

export const getPermissions = state => {
  const isOldForum = Boolean(getCommunityId(state));
  const isMemberAppInstalled = get(
    getApplicationConfig(state, MEMBERS_APP_ID),
    'isInstalled',
    false,
  );
  const isMemberAreaInstalled = get(
    getApplicationConfig(state, SANTA_MEMBERS_APP_ID),
    'isInstalled',
    false,
  );
  const flags = { isOldForum, isMemberAppInstalled, isMemberAreaInstalled };

  return permissionsChecker(flags, getCurrentUser(state), resource =>
    resourceResolver(state)(resource),
  );
};

const resourceResolver = state => resource => {
  if (resource.categoryId) {
    resource = getCategory(state, resource.categoryId);
  }
  return resource;
};
